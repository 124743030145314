<template>
<div class="bg-bramslo-900 w-screen h-screen antialiased py-2">
  <nav class="border-gray-200 py-2.5 mb-1">
  <div class="container flex flex-wrap px-4 items-center justify-between mx-auto">
    <a href="https://www.bramslo17.online/" class="flex items-center">
        <img src="@/assets/icon.jpg" class="w-20 md:w-28" alt="Bramslo17 Logo" />
        <!-- <span class="self-center text-xl font-semibold whitespace-nowrap dark:text-bramslo-50">Flowbite</span> -->
    </a>
    <button data-collapse-toggle="navbar-default" type="button" class="inline-flex items-center p-2 ml-3 text-sm text-gray-50 items-center md:hidden" aria-controls="navbar-default" aria-expanded="false">
      <span class="sr-only">Navigation</span>
      <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
    </button>
    <div class="hidden w-full md:block md:w-auto">
      <ul class="bramslo-front-menu flex flex-col p-4 mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium ">
        <li>
          <!-- <a href="#" class="block py-2 pl-3 pr-4 text-bramslo-50 ">Accueil</a> -->
          <router-link class="block py-2 pl-3 pr-4 text-bramslo-50 " to="/">Accueil</router-link>
        </li>
        <li>
          <router-link class="block py-2 pl-3 pr-4 text-bramslo-50 " to="/porfolio">Porfolio</router-link>

          <!-- <a href="#" class="block py-2 pl-3 pr-4 text-bramslo-50 rounded">Porfolio</a> -->
        </li>
        <li>
          <a href="#" class="block py-2 pl-3 pr-4 text-bramslo-50 rounded">Services</a>
        </li>
        <li>
          <a href="#" class="block py-2 pl-3 pr-4 text-bramslo-50 rounded">Blog</a>
        </li>
        <li>
          <a href="#" class="block py-2 pl-3 pr-4 text-bramslo-50 rounded">Soumettre un project</a>
        </li>
      </ul>
    </div>
  </div>
</nav>

  <router-view/>
</div>
</template>
<script>
export default{
  name:'App',
}
</script>
<style>
.bramslo-front-menu a.router-link-exact-active {
    border: 1px solid rgb(229 231 235);
    border-radius: 0.5rem;
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
   cursor: pointer;
 }
</style>